import fetch from "auth/FetchInterceptor";

const SuperAdminService = {};

SuperAdminService.getAllTransactions = function (
    startDate,
    endDate,
    restaurantId
) {
    return fetch({
        url: `/api/superadmin`,
        method: "post",
        data: { startDate, endDate },
    });
};

SuperAdminService.getBestsellers = function () {
    return fetch({
        url: `/api/superadmin/bestsellers`,
        method: "get",
    });
};

SuperAdminService.getTodaysSales = function () {
    return fetch({
        url: `/api/superadmin/today-sales`,
        method: "get",
    });
};
SuperAdminService.getLifetimeSales = function () {
    return fetch({
        url: `/api/superadmin/total-sales`,
        method: "get",
    });
};

export default SuperAdminService;
