import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN, REFRESH_TOKEN } from 'constants/AuthConstant';
import AuthService from 'services/AuthService';
import { getPrinterDetails, getRestaurantDetail } from './restaurantSlice';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	isAdmin: false,
	isRestaurant: false,
	adminToken: null,
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const signIn = createAsyncThunk('auth/login', async (data, { rejectWithValue, dispatch }) => {
	const { email, password } = data;
	try {
		const response = await AuthService.login({ email, password });
		const token = response.access_token;
		const refreshToken = response.refresh_token;
		localStorage.setItem(AUTH_TOKEN, token);
		localStorage.setItem(REFRESH_TOKEN, refreshToken);
		dispatch(getRestaurantDetail())
		dispatch(getPrinterDetails());
		return token;
	} catch (err) {
		console.log('error', err);
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
	try {
		const token = localStorage.getItem(REFRESH_TOKEN);
		const response = await AuthService.logout(token);
		localStorage.clear();
		return response.data
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || 'Error');
	}
});

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.isAdmin = false
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.isRestaurant = true
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.isAdmin = false
				state.isRestaurant = false
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer