import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import InventoryService from 'services/InventoryService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
    products: [],
}

export const getAllProducts = createAsyncThunk('inventory/get-all-products',async (_, { rejectWithValue }) => {
	try {
		const response = await InventoryService.getAllProducts();
        const products = response?.map(product => {
            return {
                id: product?.id,
                name: product?.name,
                image: product?.image_url,
                category: product?.category,
                stock: product?.inventory?.reduce((total, inventory) =>  total + inventory.quantity,0),
                description: product?.description,
                inventory: product?.inventory,
                details: product?.details,
            }
        })
        return products;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
});

export const addProducts = createAsyncThunk('inventory/add-products', async (data, {rejectWithValue}) => {
    try {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('details', data.details);
        formData.append('category', data.category);
        formData.append('inventory', JSON.stringify(data.inventory));
        data.image?.fileList?.forEach((file, index) => {
            formData.append('image', file.originFileObj);
        });
        const response = await InventoryService.addProducts(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Error');
    }
});

export const deleteProduct = createAsyncThunk('inventory/delete-products', async (id, {rejectWithValue}) => {
    try {
        const response = await InventoryService.deleteProducts(id);
        return response;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Error');
    }
});

export const updateProduct = createAsyncThunk('inventory/update-products', async (_data, {rejectWithValue}) => {
    try {
        const {id, data} = _data;
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('details', data.details);
        formData.append('category', data.category);
        formData.append('inventory', JSON.stringify(data.inventory));
        formData.append('image_url', JSON.stringify(data.uploadedImg));
        data?.image?.fileList?.forEach((file) => {
            formData.append('image', file?.originFileObj);
        })
        const response = await InventoryService.updateProducts(id, formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Error');
    }
})

export const inventorySlice = createSlice({
	name: 'inventory',
	initialState,
	reducers: {
		clearMessage: (state) => {
            state.message = '';
        }
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllProducts.pending, (state) => {
				state.loading = true
			})
			.addCase(getAllProducts.fulfilled, (state, action) => {
				state.loading = false
				state.products = action.payload;
			})
			.addCase(getAllProducts.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
            .addCase(addProducts.pending, (state) => {
                state.loading = true;
            })
            .addCase(addProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.message = action.payload?.message;
            })
            .addCase(addProducts.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload;
                state.showMessage = true;
            })
            .addCase(updateProduct.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                state.loading = false;
                state.message = action.payload?.message;
            })
            .addCase(updateProduct.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload;
                state.showMessage = true;
            })
            .addCase(deleteProduct.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.loading = false;
                state.message = action.payload?.message;
            })
            .addCase(deleteProduct.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload;
                state.showMessage = true;
            })
	},
})

export const { 
    clearMessage,
} = inventorySlice.actions

export default inventorySlice.reducer