import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TransactionsService from "services/TransactionsServices";

export const initialState = {
    loading: false,
    message: "",
    showMessage: false,
    transactions: [],
    bestsellers: [],
    todaysSales: null,
    lifetimeSales: null
};

export const getAllTransactions = createAsyncThunk(
    "transactions/get-all-transactions",
    async (data, { rejectWithValue }) => {
        try {
            const startDate = data.startDate
            const endDate = data.endDate
            const restaurantId = data.restaurantId
            
            const response = await TransactionsService.getAllTransactions(startDate, endDate, restaurantId);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

export const getBestsellers = createAsyncThunk(
    "transactions/get-bestsellers",
    async (data, { rejectWithValue }) => {
        try {
            const restaurantId = data.restaurantId
            
            const response = await TransactionsService.getBestsellers(restaurantId);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

export const getTodaysSales = createAsyncThunk(
    "transactions/get-todays-sales",
    async (data, { rejectWithValue }) => {
        try {
            const restaurantId = data.restaurantId
            
            const response = await TransactionsService.getTodaysSales(restaurantId);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);
export const getLifetimeSales = createAsyncThunk(
    "transactions/get-lifetime-sales",
    async (data, { rejectWithValue }) => {
        try {
            const restaurantId = data.restaurantId
            
            const response = await TransactionsService.getLifetimeSales(restaurantId);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);


export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllTransactions.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllTransactions.fulfilled, (state, action) => {
                state.loading = false;
                state.transactions = action.payload;
            })
            .addCase(getAllTransactions.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(getBestsellers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getBestsellers.fulfilled, (state, action) => {
                state.loading = false;
                state.bestsellers = action.payload;
            })
            .addCase(getBestsellers.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(getTodaysSales.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTodaysSales.fulfilled, (state, action) => {
                state.loading = false;
                state.todaysSales = action.payload;
            })
            .addCase(getTodaysSales.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(getLifetimeSales.pending, (state) => {
                state.loading = true;
            })
            .addCase(getLifetimeSales.fulfilled, (state, action) => {
                state.loading = false;
                state.lifetimeSales = action.payload;
            })
            .addCase(getLifetimeSales.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
    },
});

export default dashboardSlice.reducer;
