import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GalleryService from 'services/GalleryService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
    gallery: [],
}

export const getAllGallery = createAsyncThunk('gallery/get-all-art',async (_, { rejectWithValue }) => {
	try {
		const response = await GalleryService.getAllGallery();
        const gallery = response?.map(product => {
            return {
                id: product?.id,
                name: product?.name,
                image: product?.image_url,
                category: product?.category,
                description: product?.description,
            }
        })
        return gallery;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
});

export const addGallery = createAsyncThunk('gallery/add-art', async (data, {rejectWithValue}) => {
    try {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('category', data.category);
        data.image?.fileList?.forEach((file, index) => {
            formData.append('image', file.originFileObj);
        });
        const response = await GalleryService.addGallery(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Error');
    }
});

export const deleteGallery = createAsyncThunk('gallery/delete-art', async (id, {rejectWithValue}) => {
    try {
        const response = await GalleryService.deleteGallery(id);
        return response;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Error');
    }
});

export const updateGallery = createAsyncThunk('gallery/update-art', async (_data, {rejectWithValue}) => {
    try {
        const {id, data} = _data;
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('category', data.category);
        formData.append('image_url', JSON.stringify(data.uploadedImg));
        data?.image?.fileList?.forEach((file) => {
            formData.append('image', file?.originFileObj);
        })
        const response = await GalleryService.updateGallery(id, formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Error');
    }
})

export const gallerySlice = createSlice({
	name: 'gallery',
	initialState,
	reducers: {
		clearMessage: (state) => {
            state.message = '';
        }
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllGallery.pending, (state) => {
				state.loading = true
			})
			.addCase(getAllGallery.fulfilled, (state, action) => {
				state.loading = false
				state.gallery = action.payload;
			})
			.addCase(getAllGallery.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
            .addCase(addGallery.pending, (state) => {
                state.loading = true;
            })
            .addCase(addGallery.fulfilled, (state, action) => {
                state.loading = false;
                state.message = action.payload?.message;
            })
            .addCase(addGallery.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload;
                state.showMessage = true;
            })
            .addCase(updateGallery.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateGallery.fulfilled, (state, action) => {
                state.loading = false;
                state.message = action.payload?.message;
            })
            .addCase(updateGallery.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload;
                state.showMessage = true;
            })
            .addCase(deleteGallery.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteGallery.fulfilled, (state, action) => {
                state.loading = false;
                state.message = action.payload?.message;
            })
            .addCase(deleteGallery.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload;
                state.showMessage = true;
            })
	},
})

export const { 
    clearMessage,
} = gallerySlice.actions

export default gallerySlice.reducer