import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: "restaurants.default",
        path: `${APP_PREFIX_PATH}/restaurants`,
        component: React.lazy(() =>
            import("views/app-views/restaurants/restaurants-list")
        ),
    },
    {
        key: "admindashboard.default",
        path: `${APP_PREFIX_PATH}/admin-dashboard`,
        component: React.lazy(() =>
            import("views/app-views/super-admin-dashboard")
        ),
    },
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboard`,
        component: React.lazy(() => import('views/app-views/dashboard')),
    },
    {
        key: 'order.default',
        path: `${APP_PREFIX_PATH}/orders`,
        component: React.lazy(() => import('views/app-views/orders')),
    },
    {
        key: 'order.orders-list',
        path: `${APP_PREFIX_PATH}/orders/orders-list`,
        component: React.lazy(() => import('views/app-views/orders/orders-list')),
    },
    {
        key: 'order.order-details',
        path: `${APP_PREFIX_PATH}/orders/order-details/:orderId`,
        component: React.lazy(() => import('views/app-views/orders/order-details')),
    },
    {
        key: 'settlements.default',
        path: `${APP_PREFIX_PATH}/settlements`,
        component: React.lazy(() => import('views/app-views/settlements')),
    },
    {
        key: 'menu.default',
        path: `${APP_PREFIX_PATH}/menu`,
        component: React.lazy(() => import('views/app-views/menu')),
    },
    {
        key: 'menu.menu-list',
        path: `${APP_PREFIX_PATH}/menu/menu-list`,
        component: React.lazy(() => import('views/app-views/menu/menu-list')),
    },
    {
        key: 'setting.default',
        path: `${APP_PREFIX_PATH}/setting/*`,
        component: React.lazy(() => import('views/app-views/setting')),
    },
]