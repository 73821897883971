import fetch from 'auth/FetchInterceptor'

const OrdersService = {}

OrdersService.getAllOrders = function () {
  return fetch({
    url: '/api/orders/get-orders',
    method: 'get',
  })
}

OrdersService.getOrders = function (id) {
  return fetch({
    url: `/api/orders/${id}`,
    method: 'get'
  });
}

OrdersService.updateStatus = function (id, data) {
  return fetch({
    url: `/api/orders/${id}/update-status`,
    method: 'put',
    data: data
  })
}

export default OrdersService