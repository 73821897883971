import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RestaurantService from "services/RestaurantService";

export const initialState = {
    loading: false,
    message: "",
    showMessage: false,
    restaurant: {
        id: 0,
        name: "",
        email: "",
        gstin: "",
        phone_number: "",
        city: "",
        postal_code: "",
        image_url: "",
    },
    restaurants: [],
    menuCategory: [],
    menuItems: [],
    settlements: [],
    printerDetails: {
        "printer_id": "",
        "access_token": "",
    }
};

export const getAllRestaurants = createAsyncThunk(
    "restaurant/get-all-restaurants",
    async (_, { rejectWithValue }) => {
        try {
            const response = await RestaurantService.getAllRestaurants();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

export const getRestaurantDetail = createAsyncThunk(
    "restaurant/get-restaurant-details",
    async (_, { rejectWithValue }) => {
        try {
            const response = await RestaurantService.getRestaurantDetail();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

export const updateRestaurantDetails = createAsyncThunk(
    "restaurant/update-restaurant-details",
    async (data, { rejectWithValue }) => {
        try {
            const response = await RestaurantService.updateRestaurantDetails(
                data
            );
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

export const changeRestaurantLogo = createAsyncThunk(
    "restaurant/change-logo",
    async (formData, { rejectWithValue }) => {
        try {
            const response = await RestaurantService.changeRestaurantLogo(
                formData
            );
            return response.rows[0].image_url;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

export const editMenuCategory = createAsyncThunk(
    "restaurant/edit-menu-category",
    async (data, { rejectWithValue, getState, dispatch }) => {
        try {
            const response = await RestaurantService.editMenuCategory({
                name: data.categoryName,
                ...data,
            });
            dispatch(getMenuCategory());
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "Error");
        }
    }
);

export const saveMenuCategory = createAsyncThunk(
    "restaurant/save-menu-category",
    async (data, { rejectWithValue, getState, dispatch }) => {
        const { categoryName } = data;
        const { id } = getState().restaurant.restaurant;
        try {
            const response = await RestaurantService.saveMenuCategory({
                name: categoryName,
                restaurant_id: id,
            });
            dispatch(getMenuCategory());
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "Error");
        }
    }
);

export const getMenuCategory = createAsyncThunk(
    "restaurant/get-menu-category",
    async (_, { rejectWithValue, getState }) => {
        const { id } = getState().restaurant.restaurant;
        try {
            const response = await RestaurantService.getMenuCategory(id);
            return response;
        } catch (error) {
            return rejectWithValue(error.response || "Error");
        }
    }
);

export const saveMenuItems = createAsyncThunk(
    "restaurant/save-menu-items",
    async (data, { rejectWithValue, getState, dispatch }) => {
        const { id } = getState().restaurant.restaurant;
        try {
            const response = await RestaurantService.saveMenuItems({
                restaurant_id: id,
                ...data,
            });
            dispatch(getMenuItems(id));
            return response;
        } catch (error) {
            return rejectWithValue(error.response || "Error");
        }
    }
);

export const updateMenuItemAvailability = createAsyncThunk(
    "restaurant/update-menu-item-availability",
    async (data, { rejectWithValue, getState, dispatch }) => {
        const { id } = getState().restaurant.restaurant;
        try {
            const response = await RestaurantService.updateItemAvailability(
                data.menuId,
                { availability: data.availability }
            );
            dispatch(getMenuItems(id));
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "Error");
        }
    }
);

export const updateMenuItems = createAsyncThunk(
    "restaurant/update-menu-item",
    async (data, { rejectWithValue, getState, dispatch }) => {
        const { id } = getState().restaurant.restaurant;
        try {
            const response = await RestaurantService.updateMenuItems(data.id, {
                ...data,
                restaurant_id: id,
            });
            dispatch(getMenuItems(id));
            return response;
        } catch (error) {
            return rejectWithValue(error.response || "Error");
        }
    }
);

export const getMenuItems = createAsyncThunk(
    "restaurant/get-menu-items",
    async (_, { rejectWithValue, getState }) => {
        const { id } = getState().restaurant.restaurant;
        try {
            const response = await RestaurantService.getMenuItems(id);
            return response;
        } catch (error) {
            return rejectWithValue(error.response || "Error");
        }
    }
);

export const deleteMenuItem = createAsyncThunk(
    "restaurant/delete-menu-items",
    async (menuItemId, { rejectWithValue, dispatch, getState }) => {
        const { id } = getState().restaurant.restaurant;
        try {
            const response = await RestaurantService.deleteMenuItems(
                menuItemId
            );
            dispatch(getMenuItems(id));
            return response;
        } catch (error) {
            return rejectWithValue(error.response || "Error");
        }
    }
);

export const deleteCategoryItem = createAsyncThunk(
    "restaurant/delete-category-items",
    async (categoryId, { rejectWithValue, dispatch }) => {
        try {
            const response = await RestaurantService.deleteMenuCategory(
                categoryId
            );
            dispatch(getMenuCategory());
            return response;
        } catch (error) {
            return rejectWithValue(error.response || "Error");
        }
    }
);

export const getSettlements = createAsyncThunk(
    "restaurant/get-settlements",
    async (_, {rejectWithValue, getState}) => {
        try {
            const { id } = getState().restaurant.restaurant;
            const response = await RestaurantService.getSettlements(id);
            return response;
        } catch (error) {
            return rejectWithValue(error.response || "Error");
        }
    }
)

export const getPrinterDetails = createAsyncThunk(
    "restaurant/get-printer-details",
    async (_, {rejectWithValue, getState}) => {
        try {
            const { id } = getState().restaurant.restaurant;
            const response = await RestaurantService.getPrinterDetails(id);
            console.log('response', response);
            return response;
        } catch (error) {
            return rejectWithValue(error.response || "error");
        }
    }
)

export const restaurantSlice = createSlice({
    name: "restaurant",
    initialState,
    reducers: {
        removeRestaurantData: (state) => {
            state.restaurant = {
                id: 0,
                name: "",
                email: "",
                gstin: "",
                phone_number: "",
                city: "",
                postal_code: "",
                image_url: "",
            };
            state.menuCategory = [];
            state.menuItems = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllRestaurants.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllRestaurants.fulfilled, (state, action) => {
                state.loading = false;
                state.restaurants = { ...action.payload };
            })
            .addCase(getAllRestaurants.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
            .addCase(getRestaurantDetail.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRestaurantDetail.fulfilled, (state, action) => {
                state.loading = false;
                state.restaurant = { ...action.payload };
            })
            .addCase(getRestaurantDetail.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
            .addCase(updateRestaurantDetails.pending, (state) => {
                state.loading = true;
                state.showMessage = false;
            })
            .addCase(updateRestaurantDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.restaurant = action.payload;
            })
            .addCase(updateRestaurantDetails.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
            .addCase(changeRestaurantLogo.pending, (state) => {
                state.loading = true;
                state.showMessage = false;
            })
            .addCase(changeRestaurantLogo.fulfilled, (state, action) => {
                state.loading = false;
                state.restaurant.image_url = action.payload;
            })
            .addCase(changeRestaurantLogo.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
            .addCase(saveMenuCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(saveMenuCategory.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(saveMenuCategory.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
            .addCase(getMenuCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(getMenuCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.menuCategory = action.payload;
            })
            .addCase(getMenuCategory.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
            .addCase(saveMenuItems.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(saveMenuItems.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(saveMenuItems.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
            .addCase(updateMenuItems.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateMenuItems.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateMenuItems.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
            .addCase(getMenuItems.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getMenuItems.fulfilled, (state, action) => {
                state.loading = false;
                state.menuItems = action.payload;
            })
            .addCase(getMenuItems.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
            .addCase(deleteMenuItem.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteMenuItem.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(deleteMenuItem.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
            .addCase(deleteCategoryItem.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteCategoryItem.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(deleteCategoryItem.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
            .addCase(getSettlements.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSettlements.fulfilled, (state, action) => {
                state.loading = false;
                state.settlements = action.payload;
            })
            .addCase(getSettlements.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
            .addCase(getPrinterDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPrinterDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.printerDetails = action.payload;
            })
            .addCase(getPrinterDetails.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
    },
});

export const { removeRestaurantData } = restaurantSlice.actions;
export default restaurantSlice.reducer;
