import fetch from "auth/FetchInterceptor";

const TransactionsService = {};

TransactionsService.getAllTransactions = function (startDate, endDate, restaurantId) {
    
    return fetch({
        url: `/api/transactions`,
        method: "post",
        data: { startDate, endDate, restaurantId },
    });
};

TransactionsService.getBestsellers = function (restaurantId) {
    
    return fetch({
        url: `/api/transactions/bestsellers/${restaurantId}`,
        method: "get",
    });
};

TransactionsService.getTodaysSales = function (restaurantId) {
    
    return fetch({
        url: `/api/transactions/today-sales/${restaurantId}`,
        method: "get",
    });
};
TransactionsService.getLifetimeSales = function (restaurantId) {
    
    return fetch({
        url: `/api/transactions/total-sales/${restaurantId}`,
        method: "get",
    });
};

export default TransactionsService;
