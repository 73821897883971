import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.login = function (data) {
    return fetch({
        url: "/api/auth/login",
        method: "post",
        data: data,
    });
};

AuthService.register = function (data) {
    return fetch({
        url: "/auth/auth/register",
        method: "post",
        data: data,
    });
};

AuthService.logout = function (data) {
    return fetch({
        url: "/api/auth/logout",
        method: "post",
        data: data,
    });
};

export default AuthService;
