import fetch from 'auth/FetchInterceptor'

const RestaurantService = {}

RestaurantService.getAllRestaurants = function () {
    return fetch({
        url: "/api/superadmin/get-all-restaurants",
        method: "get",
    });
};

RestaurantService.getRestaurantDetail = function () {
  return fetch({
    url: '/api/restaurant',
    method: 'get',
  });
}

RestaurantService.updateRestaurantDetails = function (data) {
  console.log(data)
  return fetch({
    url: '/api/restaurant/update',
    method: 'put',
    data: data
  });
}

RestaurantService.changeRestaurantLogo = function (formData) {
    return fetch({
        url: `/api/restaurant/update-image`,
        method: "put",
        data: formData,
    });
};

RestaurantService.saveMenuCategory = function (data) {
  return fetch({
    url: '/api/menu/categories',
    method: 'post',
    data: data
  });
}

RestaurantService.editMenuCategory = function (data) {
  return fetch({
    url: `/api/menu/categories/${data.id}`,
    method: 'put',
    data: data
  });
}

RestaurantService.getMenuCategory = function (restaurantId) {
  return fetch({
    url: `/api/menu/categories/${restaurantId}`,
    method: 'get',
  });
}

RestaurantService.saveMenuItems = function (data) {
  return fetch({
    url: '/api/menu/items',
    method: 'post',
    data: data,
  });
}

RestaurantService.getMenuItems = function (restaurantId) {
  return fetch({
    url: `/api/menu/items/${restaurantId}`,
    method: 'get',
  });
}

RestaurantService.updateItemAvailability = function (menuId, data) {
  return fetch({
      url: `/api/menu/items/${menuId}/availability`,
      method: "put",
      data,
  });
}

RestaurantService.updateMenuItems = function (restaurantId, data) {
  return fetch({
    url: `/api/menu/items/${restaurantId}`,
    method: 'put',
    data: data,
  });
}

RestaurantService.deleteMenuItems = function (menuId) {
  return fetch({
    url: `/api/menu/items/${menuId}`,
    method: 'delete',
  });
}

RestaurantService.deleteMenuCategory = function (categoryId) {
  return fetch({
      url: `/api/menu/categories/${categoryId}`,
      method: "delete",
  });
}

RestaurantService.getSettlements = function (id) {
  return fetch({
    url: `/api/settlements/get-settlements/${id}`,
    method: 'get',
  })
}

RestaurantService.getPrinterDetails = function (id) {
  return fetch({
    url: `/api/restaurant/get-printer-details/${id}`,
    method: 'get',
  })
}

export default RestaurantService