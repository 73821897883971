import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import OrdersService from 'services/OrdersService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
    orders: [],
    order: {},
}

export const getAllOrders = createAsyncThunk('orders/get-all-orders',async (_, { rejectWithValue }) => {
	try {
		const response = await OrdersService.getAllOrders();
        return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
});

export const getOrders = createAsyncThunk('orders/get-orders', async (_, {rejectWithValue, getState}) => {
    try {
		const { id } = getState().restaurant.restaurant;
        const response = await OrdersService.getOrders(id);
        return response;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Error');
    }
});

export const updateOrderStatus = createAsyncThunk('orders/update-order-status', async (data, {rejectWithValue, dispatch}) => {
	const {id, orderStatus} = data;
	try {
		const response = await OrdersService.updateStatus(id, {orderStatus});
		return response;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || 'Error');
	} finally {
		// dispatch(getOrder(id))
	}
})

export const ordersSlice = createSlice({
	name: 'orders',
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(getAllOrders.pending, (state) => {
				state.loading = true
			})
			.addCase(getAllOrders.fulfilled, (state, action) => {
				state.loading = false
				state.orders = action.payload;
			})
			.addCase(getAllOrders.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
            .addCase(getOrders.fulfilled, (state, action) => {
                state.loading = false;
                state.orders = action.payload;
            })
            .addCase(getOrders.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOrders.rejected, (state, action) => {
                state.loading = false;
                state.showMessage = true;
                state.message = action.payload;
            })
			.addCase(updateOrderStatus.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateOrderStatus.fulfilled, (state, action) => {
				state.loading = false;
				state.message = action.payload;
			})
			.addCase(updateOrderStatus.rejected, (state, action) => {
				state.loading = false;
				state.message = action.payload;
				state.showMessage = true;
			})
	},
})

export default ordersSlice.reducer