import React  from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY, AUTHENTICATED_ENTRY_SUPER_ADMIN } from 'configs/AppConfig'

const PublicRoute = () => {

	const { token } = useSelector(state => state.auth)
	const { isAdmin, isRestaurant } = useSelector(state => state.auth)
  
	return token ? (
        <Navigate to={(isAdmin && !isRestaurant) ? AUTHENTICATED_ENTRY_SUPER_ADMIN : AUTHENTICATED_ENTRY} />
    ) : (
        <Outlet />
    );
}

export default PublicRoute