import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import inventory from './slices/inventorySlice';
import orders from './slices/ordersSlice';
import gallery from './slices/gallerySlice';
import restaurant from './slices/restaurantSlice';
import dashboard from './slices/dashboardSlice';
import superAdminDashboard from './slices/superAdminDashboardSlice';

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        inventory,
        orders,
        gallery,
        restaurant,
        dashboard,
        superAdminDashboard,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
