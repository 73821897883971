import fetch from 'auth/FetchInterceptor'

const GalleryService = {}

GalleryService.getAllGallery = function () {
  return fetch({
    url: '/api/get-gallery',
    method: 'get',
  })
}

GalleryService.addGallery = function (data) {
  return fetch({
    url: '/api/add-gallery',
    method: 'post',
    data: data
  })
}

GalleryService.deleteGallery = function (id) {
  return fetch({
    url: `/api/delete-gallery/${id}`,
    method: 'delete',
  })
}

GalleryService.updateGallery = function (id, data) {
  return fetch ({
    url: `/api/update-gallery/${id}`,
    method: 'put',
    data: data
  })
}

export default GalleryService