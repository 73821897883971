import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN, REFRESH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';

const unauthorizedCode = [401, 403]

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
	}
	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {
	return response.data
}, async (error) => {
	const originalRequest = error.config;

	let notificationParam = {
		message: ''
	}

	// Remove token and redirect
	if (unauthorizedCode.includes(error?.response?.status) && !originalRequest?._retry) {
		notificationParam.message = 'Authentication Failed'
		notificationParam.description = error.response.error
		// localStorage.removeItem(AUTH_TOKEN);

		// store.dispatch(signOutSuccess())
		originalRequest._retry = true;
		const refreshToken = localStorage.getItem(REFRESH_TOKEN);
		if (!refreshToken) {
			// Navigate to login screen if refreshToken is not present
			store.dispatch(signOutSuccess())
			return Promise.reject(error);
		}

		try {
			const response = await axios.post(API_BASE_URL + '/api/token', { token: refreshToken });
			const newAccessToken = response.data.access_token;
			localStorage.setItem(AUTH_TOKEN, newAccessToken);
			originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
			return service(originalRequest);
		} catch (error) {
			// Navigate to login screen if token refresh fails
			store.dispatch(signOutSuccess());
			return Promise.reject(error);
		}
	}


	if (error?.response?.status === 404) {
		notificationParam.message = 'Not Found'
	}

	if (error?.response?.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}

	if (error?.response?.status === 508) {
		notificationParam.message = 'Time Out'
	}

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service