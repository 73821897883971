import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SuperAdminService from "services/SuperAdminService";

export const initialState = {
    loading: false,
    message: "",
    showMessage: false,
    transactions: [],
    bestsellers: [],
    todaysSales: null,
    lifetimeSales: null,
};

export const getSuperAdminAllTransactions = createAsyncThunk(
    "superAdminTransactions/get-all-transactions",
    async (data, { rejectWithValue }) => {
        try {
            const startDate = data.startDate;
            const endDate = data.endDate;

            const response = await SuperAdminService.getAllTransactions(
                startDate,
                endDate,
            );
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

export const getSuperAdminBestsellers = createAsyncThunk(
    "superAdminTransactions/get-bestsellers",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SuperAdminService.getBestsellers();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

export const getSuperAdminTodaysSales = createAsyncThunk(
    "superAdminTransactions/get-todays-sales",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SuperAdminService.getTodaysSales();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);
export const getSuperAdminLifetimeSales = createAsyncThunk(
    "superAdminTransactions/get-lifetime-sales",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SuperAdminService.getLifetimeSales();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

export const superAdminDashboardSlice = createSlice({
    name: "superAdminDashboard",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getSuperAdminAllTransactions.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSuperAdminAllTransactions.fulfilled, (state, action) => {
                state.loading = false;
                state.transactions = action.payload;
            })
            .addCase(getSuperAdminAllTransactions.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(getSuperAdminBestsellers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSuperAdminBestsellers.fulfilled, (state, action) => {
                state.loading = false;
                state.bestsellers = action.payload;
            })
            .addCase(getSuperAdminBestsellers.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(getSuperAdminTodaysSales.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSuperAdminTodaysSales.fulfilled, (state, action) => {
                state.loading = false;
                state.todaysSales = action.payload;
            })
            .addCase(getSuperAdminTodaysSales.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(getSuperAdminLifetimeSales.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSuperAdminLifetimeSales.fulfilled, (state, action) => {
                state.loading = false;
                state.lifetimeSales = action.payload;
            })
            .addCase(getSuperAdminLifetimeSales.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            });
    },
});

export default superAdminDashboardSlice.reducer;
