import fetch from 'auth/FetchInterceptor'

const InventoryService = {}

InventoryService.getAllProducts = function () {
  return fetch({
    url: '/api/get-products',
    method: 'get',
  })
}

InventoryService.addProducts = function (data) {
  return fetch({
    url: '/api/add-product',
    method: 'post',
    data: data
  })
}

InventoryService.deleteProducts = function (id) {
  return fetch({
    url: `/api/delete-product/${id}`,
    method: 'delete',
  })
}

InventoryService.updateProducts = function (id, data) {
  return fetch ({
    url: `/api/update-product/${id}`,
    method: 'put',
    data: data
  })
}

export default InventoryService